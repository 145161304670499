/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-mixed-operators */

import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { HotKeys } from 'react-hotkeys';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Query } from 'react-apollo';
import { Redirect, withRouter, Prompt } from 'react-router-dom';
import Stop from '@material-ui/icons/Stop';
import SkipNext from '@material-ui/icons/SkipNext';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import HealingIcon from '@material-ui/icons/Healing';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import Icon from '@mdi/react';
import { mdiEraser, mdiMarker, mdiFormatHorizontalAlignCenter, mdiPen } from '@mdi/js';
import GridItem from 'components/Grid/GridItem.jsx';
import NotesForm from 'components/Activity/NotesForm.js';
import GridContainer from 'components/Grid/GridContainer.jsx';
import Card from 'components/Card/Card';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { withTranslation } from 'react-i18next';
import Menu from '@material-ui/core/Menu';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Badge from '@material-ui/core/Badge';
import { SketchPicker } from 'react-color';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import moment from 'moment';
import _ from 'lodash';
import momentDurationFormat from 'moment-duration-format';
import { checkCurrentUserPermission } from 'utils/permissionChecker';
import { getCurrentUsers, getCurrentPatient } from '../../utils';
import CardBody from '../../components/Card/CardBody';
import getPlayPauseButton from '../../components/Activity/PlayPauseButton';
// import PatientConnectionStatus from '../../components/Activity/PatientConnectionStatus';
import ActivityHeader from '../../components/ActivityHeader/ActivityHeader';
import dashboardStyle from '../../assets/jss/views/dashboardStyle';
import StopActivityDialog from '../../components/Dialog/StopActivityDialog';
import {
  GET_CONFIGURATION_BY_SLUG,
  GET_ACTIVITY_BY_CHANNEL,
  GET_FULL_ACTIVITY_BY_CHANNEL,
} from '../../graphql/queries';
import RuntimeToolsOverlay from '../../components/Activity/RuntimeToolsOverlay';
import Controls from './components/Controls';
import Hypothesis from './components/Hypothesis';
import Details from './components/Details';
import { ActivityState } from './components/ActivityState';
import { logger } from '../../logger';
import AssetResolver from '../../utils/AssetResolver';
import Tools from './components/Tools';
import activities from '../list-activities.json';

const colors = {
  yellow: 'rgb(240,255,0)',
  blue: 'rgb(0,0,255)',
  red: 'rgb(255,0,0)',
};

const styles = () => ({
  badgeColor: {
    fontSize: '14px',
    '&:hover': {
      fontSize: '18px',
    },
  },
  gridContainerLive: {
    position: 'absolute',
    inset: '0',
  },
  cardLive: {
    position: 'absolute',
    inset: '70px 20px 20px 20px',
    margin: '0',
    width: 'unset',
  },
  runtimeToolsOverlayScreen: {
    /* position : 'absolute',
    top:'20px',
    left: '20px',
    right: '34%',
    bottom: '70px' */
  },
  actionsLive: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    righ: '0',
    height: '70px',
  },
  stateAndControls: {
    position: 'absolute',
    right: '10px',
    top: '20px',
    width: '32%',
    bottom: '20px',
    overflowX: 'auto',
    padding: '5px',
  },
  connectionStatus: {
    display: 'inline-block',
    marginLeft: '15px',
  },
  smallButton: {
    size: '1',
  },
});

class ActivityWrapperLive extends React.Component {
  constructor(props) {
    super(props);

    momentDurationFormat(moment);

    this.state = {
      activityStarted: false,
      activityPaused: true,
      activityChannel: '',
      activityOver: false,
      expectedAnswer: '',
      currentTurn: 0,
      numberOfErrors: 0,
      isPatientConnected: false,
      activityReadyToStart: false,
      communicationChannelJoined: false,
      stopDialogOpen: false,
      showNotes: false,
      isBlocking: false,
      isPenActive: false,
      isMarkerActive: false,
      isSeparationActive: false,
      isDrawing: false,
      separationColor: props.separationColor || colors.blue,
      penColor: props.penColor || colors.red,
      markerColor: props.markerColor || colors.yellow,
      slug: props.match.params.slug,
      commands: {},
      anchorEl: null,
      toolTarget: 'penColor',
      appliedConfiguration: JSON.parse(props.location.state.config),
      socket: window.activitySocket,
      startable: false,
      t: props.t,
      ratio: 16 / 9,
      activityStateComponentRenderable: false,
      reconfig: false,
    };
    this.state.runtimeState = { totalTime: 0 };
    this.state.activityResult = {};
    this.handleToolsChanged.bind(this);


    this.activityRunning = false;
    this.activityStartTime = 0;
    this.pauseTime = 0;
    this.cumulatedPauseTime = 0;

    this.clientsReady = 0;
  }

  componentDidMount() {
    console.log("mounting")
    // const sessionID = getCurrentPatient().code;
    //  console.log(`session ID ${sessionID}`);
    // this.state.socket.auth = { sessionID };
    // this.state.socket.connect();

    function updateConnectionStatus(socket, roomID, propKey, comp) {
      console.log("updating connection status")
      const stateProp = {};

      socket.emit('getRoomSize', roomID, (response) => {
        if (response.roomSize > 1) {
          stateProp[propKey] = true;
        } else {
          stateProp[propKey] = false;
        }
        comp.setState(stateProp);
      });
    }

    updateConnectionStatus(window.comSocket, getCurrentPatient().code, 'isPatientConnected', this);
    // updateConnectionStatus(window.activitySocket, getCurrentPatient().code, 'activityRoomFor2Connected', this);
    // setTimeout(() => {

    // subscribe to the connection event    
    window.comSocket.on('newConnection', () => {
      updateConnectionStatus(window.comSocket, getCurrentPatient().code, 'isPatientConnected', this);
    });/*
    window.activitySocket.on('newConnection', () => {
      updateConnectionStatus(
        window.activitySocket,
        getCurrentPatient().code,
        'activityRoomFor2Connected',
        this,
      );
    }); */
    // subscribe to the disconnection event
    /*
    window.comSocket.on('userDisconnected', () => {
      updateConnectionStatus(window.comSocket, getCurrentPatient().code, 'isPatientConnected', this);
      // When patient is disconnected, we leave the activity
      this.props.history.goBack();
    });
    window.activitySocket.on('userDisconnection', () => {
      updateConnectionStatus(
        window.activitySocket,
        getCurrentPatient().code,
        'activityRoomFor2Connected',
        this,
      );
    });
*/
      // recover the patient connection status
      // window.comSocket.emit('isPatientAlreadyConnected');
      //  }, 1000);


    const activityProm = import(`@covirtua-activities/${this.state.slug}/build/index.js`).then((LazyActivity) => {
      this.setState({
        activity: LazyActivity.default,
      });
    });

    const maincomProm = import(`@covirtua-activities/${this.state.slug}/build/common/mainCommands.js`).then(
      (mainCom) => {
        this.setState({
          mainCommands: mainCom.default,
        });
      },
    );

    const confProm = import(`@covirtua-activities/${this.state.slug}/build/config.js`).then((configLoad) => {
      this.setState({
        configuration: configLoad.default,
      });
    });

    const logoProm = import(`@covirtua-activities/${this.state.slug}/build/logo.png`).then((logoLoad) => {
      this.setState({
        logo: logoLoad.default,
      });
    });

    const actiStateProm = import(`@covirtua-activities/${this.state.slug}/build/activityRuntimeState.js`)
      .then((runtimeStateLoad) => {
        this.setState({
          activityStateComponent: runtimeStateLoad.default,
        });
      })
      .finally(() => {
        this.setState({
          activityStateComponentRenderable: true,
        });
      })
      .catch(() => {
        /* there is no activityRuntimeState, which is possible for some activities */
      });
    Promise.resolve(actiStateProm);

    Promise.all([activityProm, maincomProm, confProm, logoProm])
      .then(() => {})
      .catch((error) => {
        logger.error(error);
      })
      .finally(() => {
        const appliedConfTmp = this.state.appliedConfiguration;
        if (this.state.configuration.eventsConfig) {
          appliedConfTmp.eventsConfig = this.state.configuration.eventsConfig;
        }

        this.setState({
          // eslint-disable-next-line new-cap
          commands: new this.state.mainCommands(this.state.socket),
          renderable: true,
          appliedConfiguration: appliedConfTmp,
        });

        // console.log('createActivityChannel');
        //   window.comSocket.once('communicationChannelJoined', () => {
        // The emitting socket will be added to the activity room
        // this.state.socket.emit(
        //   'createActivityChannel',
        //   {
        //     slug: this.state.slug,
        //     token: window.keycloak.token,
        //     communicationChannel: JSON.parse(localStorage.getItem('currentPatient')).code,
        //   },
        //   () => {
        //     this.setState({ startable: true });
        //   },
        // );
        // });

        // if (!this.state.startable) {
        //   window.comSocket.emit('isPatientAlreadyConnected');
        // }

        this.setState({ startable: true });
      });

    this.state.socket.on('command', (data) => {
      console.log('command received:', data);
      if (data.body.name === 'expectedAnswer') {
        this.setState({ expectedAnswer: data.body.value });
      }

      /* if (data.body.name === 'clickedCell') {
        if (!data.body.value.correct) {
          const temp = this.state.runtimeState;
          temp[this.state.t('Common_Record_Error')]=this.state.runtimeState[this.state.t('Common_Record_Error')] + 1;
          this.setState({ runtimeState: temp });
        }
      } */

      /* if (data.body.value === 'rejected') {
        const temp = this.state.runtimeState;
        temp[this.state.t('Common_Record_Error')]=this.state.runtimeState[this.state.t('Common_Record_Error')] + 1;
        this.setState({ runtimeState: temp });
      } */

      if (data.body.name === 'currentTurn') {
        const temp = this.state.runtimeState;
        temp[this.state.t('Common_RuntimeView_State_Run')] = data.body.value;
        this.setState({ runtimeState: temp });
      }
    });

    this.state.socket.on('activityCreated', (activityChannel) => {
      this.setState({ activityChannel });
    });

    this.state.socket.on('pauseResumeActivity', (data) => {
      this.setState({ activityPaused: data.body.value });
    });

    this.state.socket.on('setActivityReadyToStart', () => {
      this.clientsReady += 1;
      if (this.clientsReady === 2) {
        this.setState({ activityReadyToStart: true });
      }
    });

    this.state.socket.on('completeActivity', () => {
      this.setState({ activityPaused: true, isBlocking: false, activityOver: true }, () => { });
      this.activityRunning = false;
    });

    window.comSocket.on('patientRatio', (context) => {
      this.setState({ ratio: context.ratio });
    });
/*
    this.state.socket.on('syncTime', (msg) => {
      const temp = this.state.runtimeState;
      temp.totalTime = msg.serverTime;
      this.setState({ runtimeState: temp });
    });
  */
  }

  componentWillUnmount() {
    if (this.state.activityStarted) {
      this.setState({
        activityStarted: false,
      });
    }
  }

  socketStopActivity() {
    if (!_.get(window, 'keycloak.token')) throw new Error('missing keycloak.token');
    this.state.socket.emit('stopActivity', {
      token: window.keycloak.token,
    });
  }

  socketInterruptActivity() {
    if (!_.get(window, 'keycloak.token')) throw new Error('missing keycloak.token');
    this.state.socket.emit('interruptActivity', {
      token: window.keycloak.token,
    });
  }

  socketSaveActivityResults(body) {
    if (!_.get(window, 'keycloak.token')) throw new Error('missing keycloak.token');
    this.state.socket.emit('saveActivityResults', {
      header: this.header,
      body,
      token: window.keycloak.token,
    });
  }

  handleStopDialogOpen = () => {
    this.setState({
      stopDialogOpen: true,
    });
  };

  handleStopDialogClose = () => {
    this.setState({ stopDialogOpen: false });
  };

  handleStopActivity = () => {
    this.handleStopDialogOpen();
  };

  // En cas d'arrêt on enregistre tout de même les résultats
  handleStopDialogQuit = (closingType) => {
    // const url = `/activities/${this.state.configuration.metadata.categorySlug}/${this.state.configuration.metadata.slug}`;
    // window.location.href = url;

    // history.push(url);
    this.activityRunning = false;
    const results = JSON.stringify(this.state.activityResult);

    switch (closingType) {
      case 'eAbandon': // quitting without saving (keeping the results, but will not be displayed in history!)
        this.socketSaveActivityResults({
          value: results,
        });
        this.socketStopActivity();
        setTimeout(() => {
          //   this.state.socket.disconnect();
          this.props.history.goBack();
        }, 100);
        break;
/* will be brought back when we're able to save intermediary results
      case 'eInterrupt': // saving temporary results and quitting
        this.socketSaveActivityResults({
          value: results,
        });
        this.socketInterruptActivity();
        setTimeout(() => {
          // this.state.socket.disconnect();
          this.props.history.goBack();
        }, 100);
        break;
*/
      case 'eConfig': // coming back to configuration
        setTimeout(() => {
          this.setState({ reconfig: true });
        }, 100);
        break;

      default:
        this.props.history.goBack();
        break;
    }
  };

  handleNotesClick = () => {
    this.setState({
      showNotes: !this.state.showNotes,
    });
  };

  handleClick = (event) => {
    this.state.commands.executeAction({
      value: event.currentTarget.dataset.action,
    });
  };

  handlePauseResumeActivity = () => {
    const newPauseState = !this.state.activityPaused
    this.setState({
      activityPaused: newPauseState,
    });
    this.state.commands.pauseResumeActivity({
      value: newPauseState,
    });
    if (newPauseState) {
      this.pauseTime = Date.now();
    } else if (!this.activityStartTime) {// This is the first "pauseResume", hence the activity start
        this.activityStartTime = Date.now();
        this.activityRunning = true;
      }
      else { // This is a "resume" event, let's increment the cumulated pause time
        this.cumulatedPauseTime += (Date.now() - this.pauseTime)
      }
  };


  updateTime() {
    if (!this.state.activityPaused && !this.state.activityOver) {
      const newTotalTime = Date.now() - this.activityStartTime - this.cumulatedPauseTime;
      if ((newTotalTime - this.state.runtimeState.totalTime) > 1000) {
        const tempRTS = this.state.runtimeState;
        tempRTS.totalTime = newTotalTime;
        this.setState({ runtimeState: tempRTS });
      }
    }
    if (this.activityRunning)
      setTimeout(() => { this.updateTime() }, 200);
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleColorPickerClick = (event, toolTarget) => {
    event.persist();
    this.setState({
      toolTarget,
      anchorEl: event.currentTarget,
    });
  };

  handleToolsClick = (event, tool) => {
    event.preventDefault();
    event.stopPropagation();
    this.state.commands.executeAction({
      value: event.currentTarget.dataset.action,
      color: this.state[tool],
    });
  };

  handleClickBack = () => {
    this.props.history.goBack();
  };

  handleToolsChanged = (changes) => {
    this.setState(changes);
  };

  handleColorPicked = (color, event, tool) => {
    // event.persist();
    this.setState({ [tool]: color.hex });
    this.setState({ anchorEl: null });
    this.state.commands.executeAction({
      value: 'setColor',
      color: color.hex,
      toolColor: tool,
    });
  };

  ColorToolPickerMenu(toolColor) {
    return (
      <SketchPicker
        color={this.state.markerColor}
        onChangeComplete={(color, event) => {
          this.handleColorPicked(color, event, toolColor);
        }}
      />
    );
  }

  render() {
    const { classes, t } = this.props;
    const activitiesList = activities;
    const keyMap = {
      // playPause: 'space',
      validateWith: 'f',
      success: 'v',
      stop: 's',
    };
    const handlers = {
      playPause: () => this.handlePauseResumeActivity(),
      validateWith: () => this.handleValidateWithResponse(),
      success: () => this.handleClick(),
      stop: () => this.handleStopActivity(),
    };

    let core;
    const playPauseButton = getPlayPauseButton(this.state);
    // const patientConnectionStatus = <PatientConnectionStatus isPatientConnected={this.state.isPatientConnected} />;

    if (!this.state.renderable) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', height: 60 }}>
          <CircularProgress />
        </div>
      );
    }

    if (this.state.restarted) {
      return (
        <Redirect
          to={{
            pathname: `/activities/${this.state.configuration.metadata.categorySlug}/${this.state.configuration.metadata.slug}/live`,
            state: {
              activityStarted: false,
              configSlug: this.state.configSlug,
              config: this.props.location.state.config,
            },
          }}
        />
      );
    }

    if (this.state.reconfig) {
      return (
        <Redirect
          to={{
            pathname: `/activities/${this.state.configuration.metadata.categorySlug}/${this.state.configuration.metadata.slug}/`,
            state: {
              previousConfig: this.state.appliedConfiguration,
            },
          }}
        />
      );
    }

    if (!this.props.location.state) {
      core = (
        <Redirect
          to={{
            pathname: `/activities/${this.state.configuration.metadata.categorySlug}/${this.state.configuration.metadata.slug}`,
          }}
        />
      );
    } else {
      core = (
        <div>
          {this.state.activityOver && (
            <Query
              query={GET_FULL_ACTIVITY_BY_CHANNEL}
              variables={{ channel: this.state.activityChannel }}
              fetchPolicy="cache-and-network"
            >
              {(resp) => {
                if (resp.loading)
                  return (
                    <div style={{ display: 'flex', justifyContent: 'center', height: 60 }}>
                      <CircularProgress />
                    </div>
                  );
                if (resp.error) return `Error! ${resp.error.message}`;

                return (
                  <div>
                    <Button
                      onClick={this.handleClickBack}
                      variant="contained"
                      color="primary"
                      aria-label="Back"
                      className={classes.button}
                    >
                      <ArrowBack className={classes.extendedIcon} />
                      Retour
                    </Button>
                    <Details activity={resp.data.findByActivityChannel} configuration={this.state.configuration} />
                  </div>
                );
              }}
            </Query>
          )}
          {!this.state.activityOver && (
            <GridContainer direction="row" className={classes.gridContainerLive}>
              <GridItem xs={12} sm={12} md={12}>
                <Card className={classNames(classes.cardOuter, classes.cardLive)}>
                  <CardBody>
                    <Suspense fallback={<div>Loading...</div>}>
                      <Query
                        query={GET_CONFIGURATION_BY_SLUG}
                        variables={{ slug: this.props.location.state.configSlug }}
                        fetchPolicy="cache-and-network"
                      >
                        {({ loading, error }) => {
                          if (loading) return <CircularProgress />;
                          if (error) return `Error! ${error.message}`;

                          // this.state.appliedConfiguration = JSON.parse(this.props.location.state.config);
                          this.updateTime();
                          return (
                            <div>
                              <GridContainer direction="row">
                                <GridItem xs={12} sm={12} md={8}>
                                  <div className={classes.runtimeToolsOverlayScreen}>
                                    <RuntimeToolsOverlay
                                      onToolsChange={this.handleToolsChanged}
                                      socket={this.state.socket}
                                      commands={this.state.commands}
                                      isDoctor
                                    >
                                      <Suspense fallback={<div>Chargement...</div>}>
                                        {this.state.startable ? (
                                          <this.state.activity
                                            config={this.state.appliedConfiguration}
                                            socket={this.state.socket}
                                            isPatientHere={this.state.isPatientConnected}
                                            users={getCurrentUsers()}
                                            isDoctor
                                            assetPath="/activitiesAssets/_common/"
                                            assetResolver={AssetResolver}
                                            runtimeState={this.state.runtimeState}
                                            ratio={this.state.ratio}
                                            t={t}
                                            developerConsoleLogs={checkCurrentUserPermission('feature_activityConsoleLogs')}
                                          />
                                        ) : (
                                          <div
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'center',
                                              height: 60,
                                              position: 'absolute',
                                              top: '50%',
                                              left: '50%',
                                            }}
                                          >
                                            <CircularProgress />
                                          </div>
                                        )}
                                      </Suspense>
                                    </RuntimeToolsOverlay>
                                  </div>

                                  <div className={classes.actionsLive}>
                                    <HotKeys keyMap={keyMap} handlers={handlers} style={{ outline: 0 }}>
                                      <Button
                                        onClick={this.handlePauseResumeActivity}
                                        variant="fab"
                                        color="primary"
                                        aria-label="PauseResumeActivity"
                                        className={classes.button}
                                        data-action="pauseResumeActivity"
                                        disabled={!(this.state.isPatientConnected) || !this.state.activityReadyToStart}
                                      >
                                        {playPauseButton}
                                      </Button>

                                      <Button
                                        onClick={this.handleStopActivity}
                                        variant="fab"
                                        color="primary"
                                        aria-label="StopActivity"
                                        className={classes.button}
                                        data-action="stopActivity"
                                      >
                                        <Stop />
                                      </Button>
                                      {this.state.appliedConfiguration.skipAvailable && (
                                        <Button
                                          onClick={this.handleClick}
                                          variant="contained"
                                          color="primary"
                                          aria-label="Next"
                                          className={classes.button}
                                          data-action="next"
                                          disabled={
                                            this.state.activityPaused || !this.state.appliedConfiguration.skipEnabled
                                          }
                                        >
                                          <SkipNext className={classes.extendedIcon} />
                                          {t('Common_Control_Skip')}
                                        </Button>
                                      )}
                                      {this.state.appliedConfiguration.validateAvailable && (
                                        <Button
                                          onClick={this.handleClick}
                                          variant="contained"
                                          color="primary"
                                          aria-label="validate"
                                          className={classes.button}
                                          data-action="validate"
                                          disabled={
                                            this.state.activityPaused ||
                                            !this.state.appliedConfiguration.validateEnabled
                                          }
                                        >
                                          <CheckIcon className={classes.extendedIcon} />
                                          {t('Common_Control_Validate')}
                                        </Button>
                                      )}
                                      {this.state.appliedConfiguration.acceptAvailable && (
                                        <Button
                                          onClick={this.handleClick}
                                          variant="contained"
                                          color="primary"
                                          aria-label="accept"
                                          className={classes.button}
                                          data-action="accept"
                                          disabled={
                                            this.state.activityPaused ||
                                            !this.state.appliedConfiguration.acceptAvailable
                                          }
                                        >
                                          <CheckIcon className={classes.extendedIcon} />
                                          {t('Common_Control_Accept')}
                                        </Button>
                                      )}
                                      {this.state.appliedConfiguration.validateAvailable && (
                                        <Button
                                          onClick={this.handleClick}
                                          variant="contained"
                                          color="primary"
                                          aria-label="reject"
                                          className={classes.button}
                                          data-action="reject"
                                          disabled={
                                            this.state.activityPaused ||
                                            !this.state.appliedConfiguration.validateEnabled
                                          }
                                        >
                                          <ClearIcon className={classes.extendedIcon} />
                                          {t('Common_Control_Reject')}
                                        </Button>
                                      )}

                                      {this.state.appliedConfiguration.correctAvailable && (
                                        <Button
                                          onClick={this.handleClick}
                                          variant="contained"
                                          color="primary"
                                          aria-label="Corrected"
                                          className={classes.button}
                                          data-action="corrected"
                                          disabled={
                                            this.state.activityPaused || !this.state.appliedConfiguration.correctEnabled
                                          }
                                        >
                                          <HealingIcon className={classes.extendedIcon} />
                                          {t('Common_Control_AutoCorrect')}
                                        </Button>
                                      )}

                                      {this.state.appliedConfiguration.restartAvailable && (
                                        <Button
                                          onClick={this.handleClick}
                                          variant="contained"
                                          color="primary"
                                          aria-label="Restart"
                                          className={classes.button}
                                          data-action="restart"
                                          disabled={
                                            this.state.activityPaused || !this.state.appliedConfiguration.restartEnabled
                                          }
                                        >
                                          <SettingsBackupRestoreIcon className={classes.extendedIcon} />
                                          {t('Common_Control_Startover')}
                                        </Button>
                                      )}

                                      <Tools
                                        socket={this.state.socket}
                                        config={this.state.appliedConfiguration}
                                        slug={this.state.slug}
                                        isPatientConnected={this.state.isPatientConnected}
                                        configModel={this.state.configuration}
                                      />
                                      {this.state.appliedConfiguration.runtimeToolsAllowed && (
                                        <span>
                                          <Badge
                                            onClick={(event) => {
                                              this.handleColorPickerClick(event, 'penColor');
                                            }}
                                            className={classes.badge}
                                            badgeContent={
                                              <FiberManualRecordIcon
                                                className={classes.badgeColor}
                                                style={{ color: this.state.penColor }}
                                              />
                                            }
                                          >
                                            <Button
                                              onClick={(evt) => {
                                                this.handleToolsClick(evt, 'penColor');
                                              }}
                                              variant="contained"
                                              aria-haspopup="true"
                                              color={this.state.isPenActive ? 'secondary' : 'primary'}
                                              aria-label="Draw"
                                              className={classNames(classes.smallButton, classes.button)}
                                              data-action="draw"
                                              disabled={
                                                !(this.state.isPatientConnected) ||
                                                this.state.appliedConfiguration.runtimeDrawingToolsDisabled
                                              }
                                            >
                                              <Icon path={mdiPen} className={classes.extendedIcon} size="24px" />
                                            </Button>
                                          </Badge>

                                          <Badge
                                            onClick={(event) => {
                                              this.handleColorPickerClick(event, 'markerColor');
                                            }}
                                            className={classes.badge}
                                            badgeContent={
                                              <FiberManualRecordIcon
                                                className={classes.badgeColor}
                                                style={{ color: this.state.markerColor }}
                                              />
                                            }
                                          >
                                            <Button
                                              onClick={(evt) => {
                                                this.handleToolsClick(evt, 'markerColor');
                                              }}
                                              variant="contained"
                                              color={this.state.isMarkerActive ? 'secondary' : 'primary'}
                                              aria-label="Colorize"
                                              className={classNames(classes.smallButton, classes.button)}
                                              data-action="colorize"
                                              disabled={
                                                !(this.state.isPatientConnected) ||
                                                this.state.appliedConfiguration.runtimeDrawingToolsDisabled
                                              }
                                            >
                                              <Icon path={mdiMarker} className={classes.extendedIcon} size="24px" />
                                            </Button>
                                          </Badge>

                                          <Badge
                                            onClick={(event) => {
                                              this.handleColorPickerClick(event, 'separationColor');
                                            }}
                                            className={classes.badge}
                                            badgeContent={
                                              <FiberManualRecordIcon
                                                className={classes.badgeColor}
                                                style={{ color: this.state.separationColor }}
                                              />
                                            }
                                          >
                                            <Button
                                              onClick={(evt) => {
                                                this.handleToolsClick(evt, 'separationColor');
                                              }}
                                              variant="contained"
                                              color={this.state.isSeparationActive ? 'secondary' : 'primary'}
                                              aria-label="Split"
                                              className={classNames(classes.smallButton, classes.button)}
                                              data-action="split"
                                              disabled={!(this.state.isPatientConnected)}
                                            >
                                              <Icon
                                                path={mdiFormatHorizontalAlignCenter}
                                                className={classes.extendedIcon}
                                                size="24px"
                                              />
                                            </Button>
                                          </Badge>

                                          <Menu
                                            elevation={0}
                                            getContentAnchorEl={null}
                                            anchorEl={this.state.anchorEl}
                                            keepMounted
                                            open={Boolean(this.state.anchorEl)}
                                            onClose={this.handleClose}
                                            anchorOrigin={{
                                              vertical: 'top',
                                              horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                              vertical: 'top',
                                              horizontal: 'center',
                                            }}
                                          >
                                            <SketchPicker
                                              color={this.state[this.state.toolTarget]}
                                              onChangeComplete={(color, event) => {
                                                this.handleColorPicked(color, event, this.state.toolTarget);
                                              }}
                                            />
                                          </Menu>

                                          <Button
                                            onClick={this.handleClick}
                                            variant="contained"
                                            color="primary"
                                            aria-label="Clear"
                                            className={classNames(classes.smallButton, classes.button)}
                                            data-action="clear"
                                            disabled={!(this.state.isPatientConnected)}
                                          >
                                            <Icon path={mdiEraser} className={classes.extendedIcon} size="24px" />
                                          </Button>
                                        </span>
                                      )}
                                    </HotKeys>
                                  </div>
                                </GridItem>
                                <div className={classes.stateAndControls}>
                                  <ExpansionPanel>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                      <Typography variant="h6" gutterBottom className={classes.headerFromH6}>
                                        {t('Common_RuntimeView_Header_State')}
                                      </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails style={{ width: '100%', display: 'block' }}>
                                      {(this.state.activityStateComponentRenderable &&
                                        this.state.activityStateComponent !== undefined && (
                                          <this.state.activityStateComponent
                                            runtimeState={this.state.runtimeState}
                                            config={this.state.appliedConfiguration}
                                            t={t}
                                          />
                                        )) || (
                                        <ActivityState
                                          runtimeState={this.state.runtimeState}
                                          config={this.state.configuration}
                                          t={t}
                                        />
                                      )}
                                    </ExpansionPanelDetails>
                                  </ExpansionPanel>
                                  <ExpansionPanel>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                      <Typography variant="h6" gutterBottom className={classes.headerFromH6}>
                                        {t('Common_RuntimeView_Header_Hypotheses')}
                                      </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails style={{ width: '100%' }}>
                                      <Hypothesis config={this.state.appliedConfiguration} slug={this.state.slug} />
                                    </ExpansionPanelDetails>
                                  </ExpansionPanel>

                                  <ExpansionPanel>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                      <Typography variant="h6" gutterBottom className={classes.headerFromH6}>
                                        {t('Common_RuntimeView_Header_Notepad')}
                                      </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails style={{ width: '100%', display: 'block' }}>
                                      {this.state.activityChannel && (
                                        <div>
                                          <Query
                                            query={GET_ACTIVITY_BY_CHANNEL}
                                            variables={{ channel: this.state.activityChannel }}
                                            fetchPolicy="cache-and-network"
                                          >
                                            {(resp) => {
                                              if (resp.loading) return 'Loading...';
                                              if (resp.error) return `Error! ${resp.error.message}`;
                                              return (
                                                <NotesForm
                                                  channel={this.state.activityChannel}
                                                  // notes={resp.data.findByActivityChannel.notes}
                                                />
                                              );
                                            }}
                                          </Query>
                                        </div>
                                      )}
                                    </ExpansionPanelDetails>
                                  </ExpansionPanel>

                                  <ExpansionPanel>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                      <Typography variant="h6" gutterBottom className={classes.headerFromH6}>
                                        {t('Common_RuntimeView_Header_Supervision')}
                                      </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails style={{ width: '100%' }}>
                                      <Controls
                                        socket={this.state.socket}
                                        config={this.state.appliedConfiguration}
                                        slug={this.state.slug}
                                        runtimeState={this.state.runtimeState}
                                      />
                                    </ExpansionPanelDetails>
                                  </ExpansionPanel>
                                </div>
                              </GridContainer>
                            </div>
                          );
                        }}
                      </Query>
                    </Suspense>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          )}
        </div>
      );
    }

    return (
      <div>
        <Prompt when={this.state.isBlocking} message={() => t('MBExperience_CancelCaption')} />
        <StopActivityDialog
          onQuit={this.handleStopDialogQuit}
          onClose={this.handleStopDialogClose}
          open={this.state.stopDialogOpen}
        />

        <ActivityHeader
          color={activitiesList.categories[this.state.configuration.metadata.type].color}
          logo={this.state.logo}
          header={t(this.state.configuration.metadata.name)}
          subheader={t(this.state.configuration.metadata.category)}
          patientCode={JSON.parse(sessionStorage.getItem('currentPatient')).code}
        />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {core}
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

ActivityWrapperLive.propTypes = {
  classes: PropTypes.object.isRequired,
  separationColor: PropTypes.object.isRequired,
  penColor: PropTypes.object.isRequired,
  markerColor: PropTypes.object.isRequired,
  slug: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(dashboardStyle)(withStyles(styles)(withRouter(ActivityWrapperLive))));
